/*global $*/
/*eslint no-undef: "error"*/

export default class ButtonMore {
    classButton = 'button-more--button'
    classButtonHidden = 'button-more-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let classBlock = $(this).data('class')

            $(`.${block}`).removeClass(classBlock)
            $(this).addClass(self.classButtonHidden)
        })
    }
}

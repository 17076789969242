/*global $*/
/*eslint no-undef: "error"*/

export default class Tag {
    classBlock = 'tag--block'
    classTag = 'tag--button'
    classTagActive = 'tag-active'

    classSwiperSlide = 'tag--slide'
    classSwiperHidden = 'swiper-slide-hidden'

    constructor () {
        let self = this

        $(`.${self.classTag}`).click(function () {
            let block = $(this).data('block')
            let classSwiper = $(this).data('swiper')
            let objSwiper = $(`.${classSwiper}`)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classTag}`).removeClass(self.classTagActive)
            $(this).addClass(self.classTagActive)

            objSwiper.find(`.${self.classSwiperSlide}`).addClass(self.classSwiperHidden)
            objSwiper.find(`.${self.classSwiperSlide}[data-block='${block}']`).removeClass(self.classSwiperHidden)

            objSwiper.toggleClass(`change`)
        })
    }
}

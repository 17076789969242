/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Tag from '@/assets/js/Tag/Tag'
import ButtonMore from '@/assets/js/Button/More/ButtonMore'
import ButtonTop from '@/assets/js/Button/ButtonTop/ButtonTop'
import Faq from '@/assets/js/Faq/Faq'
import Menu from '@/assets/js/Menu/Menu/Menu'
import MenuMobile from '@/assets/js/Menu/MenuMobile/MenuMobile'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import Tab from '@/assets/js/Tab/Tab'
import TabTable from '@/assets/js/Tab/TabTable/TabTable'
import TabPortfolio from '@/assets/js/Tab/TabPortfolio/TabPortfolio'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import ShowText from '@/assets/js/Show/Text/ShowText'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormPhone from '@/components/Form/FormPhone/AppFormPhone.vue'
import AppFormPhoneBottom from '@/components/Form/FormPhoneBottom/AppFormPhoneBottom.vue'
import AppFormPopup from '@/components/Form/Popup/AppFormPopup.vue'
import AppCalc from '@/components/Calc/AppCalc.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new SwiperSlider()
    new Tag()
    new ButtonMore()
    new ButtonTop()
    new Faq()
    new Menu()
    new MenuMobile()
    new HeaderMobile()
    new Tab()
    new TabTable()
    new TabPortfolio()
    new VideoImg()
    new ShowText()

    if(document.getElementById('block--form')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-phone')!= null) {
        createApp({})
            .component('block-form-phone', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-phone-bottom')!= null) {
        createApp({})
            .component('block-form-phone-bottom', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone-bottom')
    }

    if(document.getElementById('block--form-popup')!= null) {
        createApp({})
            .component('block-form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#block--form-popup')
    }

    if(document.getElementById('block--calc') != null) {
        createApp({})
            .component('block-calc', AppCalc)
            .use(Maska)
            .use(form)
            .mount('#block--calc')
    }
})

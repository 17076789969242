/*global $*/
/*eslint no-undef: "error"*/

export default class TabTable {
    classBlock = 'tab-table--block'
    classButton = 'tab-table--button'
    classButtonActive = 'tab-table-active'
    classItem = 'tab-table--item'
    classItemActive = 'button-table-active'
    classTable = 'tab-table--table'
    classTableActive = 'table-tabs__table-active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemActive)
            $(`.${self.classBlock}`).find(`.${self.classItem}[data-block='${block}']`).addClass(self.classItemActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classTable}`).removeClass(self.classTableActive)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classTable}[data-block='${block}']`).addClass(self.classTableActive)
        })

        $(`.${self.classItem}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(`.${self.classBlock}`).find(`.${self.classButton}[data-block='${block}']`).addClass(self.classButtonActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemActive)
            $(this).addClass(self.classItemActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classTable}`).removeClass(self.classTableActive)
            $(this).closest(`.${self.classBlock}`).find(`.${self.classTable}[data-block='${block}']`).addClass(self.classTableActive)
        })
    }
}

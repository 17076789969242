/*global $*/
/*eslint no-undef: "error"*/

export default class Menu {
    classItem = 'menu--item'
    classButton = 'menu--button'
    classPopup = 'menu--popup'

    classButtonOpen = 'button-catalog-open'
    classPopupOpen = 'menu__popup-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).toggleClass(self.classButtonOpen)
            $(this).closest(`.${self.classItem}`).find(`.${self.classPopup}`).toggleClass(self.classPopupOpen)
        })
    }
}

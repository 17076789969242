import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {

    createType () {
        return new Swiper('#slider--type', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--type-next',
                prevEl: '.slider--type-prev',
            },
            pagination: {
                el: '.swiper-pagination--type',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                }
            }
        })
    }

    createProduct () {
        return new Swiper('#slider--product', {
            slidesPerView: 1,
            spaceBetween: 15,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--product-next',
                prevEl: '.slider--product-prev',
            },
            pagination: {
                el: '.swiper-pagination--product',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createTag () {
        return new Swiper('#slider--tag', {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--tag-next',
                prevEl: '.slider--tag-prev',
            },
            pagination: {
                el: '.swiper-pagination--tag',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createVideo () {
        return new Swiper('#slider--video', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--video-next',
                prevEl: '.slider--video-prev',
            },
            pagination: {
                el: '.swiper-pagination--video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createVideo2 () {
        return new Swiper('#slider--video-2', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--video-2-next',
                prevEl: '.slider--video-2-prev',
            },
            pagination: {
                el: '.swiper-pagination--video-2',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    createReviewText () {
        return new Swiper('#slider--review-text', {
            slidesPerView: 2,
            spaceBetween: 20,
            loop: false,
            observer: true,
            navigation: {
                nextEl: '.slider--review-text-next',
                prevEl: '.slider--review-text-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-text',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--type')!= null) {
            this.createType()
        }

        if(document.getElementById('slider--product')!= null) {
            this.createProduct()
        }

        if(document.getElementById('slider--tag')!= null) {
            this.createTag()
        }

        if(document.getElementById('slider--video')!= null) {
            this.createVideo()
        }

        if(document.getElementById('slider--video-2')!= null) {
            this.createVideo2()
        }

        if(document.getElementById('slider--review-text')!= null) {
            this.createReviewText()
        }
    }
}

<template lang="pug">
.form-popup
    .form-popup__block(:class="{'form-popup__block-hidden': sendForm}")
        .title.title-center(v-html="formTitle")
        .form-popup__fields
            .form-popup__field
              label.input-text.input-text-gray
                span.input-text__label(v-html="formFieldNameLabel")
                input.input-text__input(
                  type="text"
                  placeholder="Иван"
                  v-model="fieldName"
                )
            .form-popup__field
              label.input-text.input-text-gray
                span.input-text__label(v-html="formFieldPhoneLabel")
                input.input-text__input(
                  type="tel"
                  placeholder="+7 ( ___ ) ___-__-__"
                  v-model="fieldPhone"
                  v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
                )
        .form-popup__consent
            label.input-checkbox
              input.input-checkbox__input(
                  type="checkbox"
                  v-model="consent"
                  value="consent"
                  checked="checked"
              )
              span.input-checkbox__label(v-html="formConsent")
        .form-popup__button
            .button.button-width(
                :class="{'button-disable': v$.$invalid}"
                @click="getSend()"
            )
              .button__name(v-html="formButtonName")

    .form-popup__block(:class="{'form-popup__block-hidden': !sendForm}")
        .form-success
            .h3.center(v-html="formSuccessTitle")
            p.center(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppFormPopup',

    setup () {
        return { v$: useVuelidate() }
    },

    props: [
        'formSettingTitle',
        'formSettingSubject',
        'formTitle',
        'formButtonName',
        'formFieldNameLabel',
        'formFieldName',
        'formFieldPhoneLabel',
        'formFieldPhone',
        'formSuccessTitle',
        'formSuccessText',
        'formConsent',
        'formRedirect'
    ],

    data () {
        return {
            fieldName: '',
            fieldPhone: '',
            consent: ['consent'],
            files: '',
            sendForm: false
        }
    },

    methods: {
        ...mapActions([
            'Send'
        ]),

        getSend () {
            if (!this.v$.$invalid) {
                this.sendForm = !this.sendForm

                this.Send({
                    setting: {
                        title: this.formSettingTitle,
                        subject: this.formSettingSubject
                    },

                    fields: [
                        {
                            title: this.formFieldName,
                            value: this.fieldName
                        },
                        {
                            title: this.formFieldPhone,
                            value: this.fieldPhone
                        }
                    ],
                    files: this.files
                })

                this.fieldName = this.fieldPhone = ''

                setTimeout(() => {
                  this.sendForm = !this.sendForm
                  window.location.href = this.formRedirect
                }, 1500)
            }
        }
    },

    validations () {
        return {
            fieldPhone: {
                required,
                minLength: minLength(17),
                maxLength: maxLength(17)
            },
            consent: {
                required
            }
        }
    }
}
</script>

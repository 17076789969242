<template lang="pug">
.form-phone
  .form-phone__tab(:class="{'form-phone__tab-hidden': sendForm}")
    .form-phone__fields
      .form-phone__field
        label.input-text.input-text-white
          span.input-text__label(v-html="formFieldNameLabel")
          input.input-text__input(
            type="text"
            placeholder="Иван"
            v-model="fieldName"
          )
      .form-phone__field
        label.input-text.input-text-white(:class="{'input-text-valid': fieldPhone.length === 17, 'input-text-error': fieldError && v$.fieldPhone.$invalid}")
          span.input-text__label(v-html="formFieldPhoneLabel")
          input.input-text__input(
            type="tel"
            placeholder="+7 ( ___ ) ___-__-__"
            v-model="fieldPhone"
            v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
          )
      .form-phone__field
        .button.button-width.button-big(@click="getSend()")
          .button__name(v-html="formButtonName")

    .form-phone__blocks
      .form-phone__block
        label.input-checkbox.input-checkbox-white
          input.input-checkbox__input(
            type="checkbox"
            v-model="consent"
            value="consent"
            checked="checked"
          )
          span.input-checkbox__label(v-html="formConsent")
      .form-phone__block
        .button.button-width.button-big(@click="getSend()")
          .button__name(v-html="formButtonName")

  .form-phone__tab(:class="{'form-phone__tab-hidden': !sendForm}")
    .form-success
      .h3.center.white(v-html="formSuccessTitle")
      p.center.white(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPopup',

  setup () {
      return { v$: useVuelidate() }
  },

  props: [
      'formSettingTitle',
      'formSettingSubject',
      'formTitle',
      'formButtonName',
      'formFieldNameLabel',
      'formFieldName',
      'formFieldPhoneLabel',
      'formFieldPhone',
      'formSuccessTitle',
      'formSuccessText',
      'formConsent',
      'formRedirect'
  ],

  data () {
      return {
        fieldName: '',
        fieldPhone: '',
        consent: ['consent'],
        files: '',
        sendForm: false,
        fieldError: false
      }
  },

  methods: {
    ...mapActions([
        'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
          window.location.href = this.formRedirect
        }, 1500)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },
      consent: {
        required
      }
    }
  }
}
</script>

<template lang="pug">
.form
    .form__block(:class="{'form__block-hidden': sendForm}")
        .form__fields
          .form__field
              label.input-text(:class="{'input-text-valid': fieldPhone.length === 17, 'input-text-error': fieldError && v$.fieldPhone.$invalid}")
                  input.input-text__input(
                      type="tel"
                      placeholder="+7 ( ___ ) ___-__-__"
                      v-model="fieldPhone"
                      v-maska="['+7 (###) ###-####', '+7 (###) ###-####']"
                  )

          .form__field
            .button.button-width.button-big.button-red(@click="getSend()")
              .button__name(v-html="formButtonName")

    .form__block(:class="{'form__block-hidden': !sendForm}")
        .form-success
            p(v-html="formSuccessText")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
    name: 'AppForm',

    setup () {
        return { v$: useVuelidate() }
    },

    props: [
        'formSettingTitle',
        'formSettingSubject',
        'formButtonName',
        'formFieldPhone',
        'formSuccessText',
        'formRedirect'
    ],

    data () {
        return {
          fieldPhone: '',
          files: '',
          sendForm: false,
          fieldError: false
        }
    },

    methods: {
        ...mapActions([
            'Send'
        ]),

        getSend () {
          if (this.v$.$invalid) {
            this.fieldError = true
          }

          if (!this.v$.$invalid) {
            this.sendForm = !this.sendForm

            this.Send({
              setting: {
                title: this.formSettingTitle,
                subject: this.formSettingSubject
              },

              fields: [
                {
                  title: this.formFieldPhone,
                  value: this.fieldPhone
                }
              ],
              files: this.files
            })

            this.fieldPhone = ''

            setTimeout(() => {
                this.sendForm = !this.sendForm
            }, 5000)
          }
      }
    },

    validations () {
        return {
            fieldPhone: {
                required,
                minLength: minLength(17),
                maxLength: maxLength(17)
            }
        }
    }
}
</script>

/*global $*/
/*eslint no-undef: "error"*/

export default class TabPortfolio {
    classBlock = 'gallery-portfolio--block'
    classButton = 'gallery-portfolio--button'
    classButtonActive = 'tab-portfolio__item-active'
    classItem = 'gallery-portfolio--item'
    classItemHidden = 'gallery-hidden'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classBlock}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).addClass(self.classItemHidden)

            if (parseInt(block) === 1) {
                $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}`).removeClass(self.classItemHidden)
            } else {
                $(this).closest(`.${self.classBlock}`).find(`.${self.classItem}[data-block='${block}']`).removeClass(self.classItemHidden)
            }
        })
    }
}

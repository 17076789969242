/*global $*/
/*eslint no-undef: "error"*/
export default class Tab {
    classHeader = 'fixed-header--block'
    classButton = 'tab--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let headerHeight = $(`.${self.classHeader}`).height() + 15
            let width = $(window).width()

            if (width > 1240) {
                headerHeight = 50
            }

            console.log(headerHeight)

            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - headerHeight
                },
                500
            )
        })
    }
}
